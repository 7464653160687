import React, { memo } from 'react';
import { getVimeoId, getYoutubeId } from 'utils/transform';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  link: string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    position: 'relative',
    paddingBottom: '62.5%',
  },
  iframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: 'none',
  },
}));

const VideoIframe: React.FC<React.PropsWithChildren<IProps>> = ({ link }) => {
  const { classes } = useStyles();

  const linkToEmbed = () => {
    let embedLink;
    if (link?.includes('youtu')) embedLink = `https://youtube.com/embed/${getYoutubeId(link)}`;
    if (link?.includes('vimeo')) embedLink = `https://player.vimeo.com/video/${getVimeoId(link)}`;
    return embedLink;
  };

  return (
    <div className={classes.wrapper}>
      <iframe src={linkToEmbed()} title={linkToEmbed()} className={classes.iframe} />
    </div>
  );
};

export default memo(VideoIframe);
