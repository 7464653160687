import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Dialog, DialogProps, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Image from 'components/common/Image';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import VideoIframe from './VideoIframe';

const useStyles = makeStyles()((theme) => ({
  control: {
    position: 'absolute',
    zIndex: 1,
    color: theme.palette.custom.white,
  },
  arrowBack: {
    top: 'calc(50% - 24px)',
    left: -72,
    [theme.breakpoints.down('md')]: {
      left: -48,
    },
  },
  arrowForward: {
    top: 'calc(50% - 24px)',
    right: -72,
    [theme.breakpoints.down('md')]: {
      right: -48,
    },
  },
  close: {
    position: 'fixed',
    top: 0,
    right: 0,
  },
  overflow: {
    ...theme.mixins.scrollBarOverflowYAuto,
  },
}));

const useDialogStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  paper: {
    width: '100%',
    backgroundColor: 'black',
    overflow: 'unset',
    margin: theme.spacing(4, 12, 4, 12),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 6, 4, 6),
    },
  },
}));

interface IProps extends DialogProps {
  initialIndex?: number;
  files: any;
}

const ImageGalleryDialog: React.FC<IProps> = ({ files, initialIndex = 0, onClose, ...props }) => {
  const { classes, cx } = useStyles();
  const { classes: dialogClasses } = useDialogStyles();

  const [index, setIndex] = useState(initialIndex);
  const isDisabledArrowBack = index === 0;
  const isDisabledArrowForward = index === files.length - 1;

  useEffect(() => {
    setIndex(initialIndex);
  }, [initialIndex]);

  const handlePrev = useCallback(() => {
    setIndex((index) => index - 1);
  }, []);

  const handleNext = useCallback(() => {
    setIndex((index) => index + 1);
  }, []);

  const handleKeyDown = (refName: any) => {
    switch (refName.keyCode) {
      case 37:
        if (!isDisabledArrowBack) handlePrev();
        break;
      case 39:
        if (!isDisabledArrowForward) handleNext();
        break;
      case 27:
        onClose({}, null);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog classes={dialogClasses} onKeyDown={handleKeyDown} maxWidth="md" onClose={onClose} {...props}>
      <IconButton className={cx(classes.control, classes.close)} onClick={() => onClose({}, null)} size="large">
        <CloseIcon fontSize="large" />
      </IconButton>
      <IconButton
        className={cx(classes.control, classes.arrowBack)}
        disabled={isDisabledArrowBack}
        onClick={handlePrev}
        size="large"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        className={cx(classes.control, classes.arrowForward)}
        disabled={isDisabledArrowForward}
        onClick={handleNext}
        size="large"
      >
        <KeyboardArrowRightIcon />
      </IconButton>

      <SwipeableViews className={classes.overflow} index={index} onChangeIndex={setIndex}>
        {files.map(({ id, image, link }: { id: number; image: any; link: string }) => {
          if (link) {
            return <VideoIframe key={id} link={link} />;
          }
          return (
            <Image
              key={id}
              src={image.original_url}
              alt={image.internal_title}
              variant="rectangle"
              objectFit="contain"
            />
          );
        })}
      </SwipeableViews>
    </Dialog>
  );
};

export default React.memo(ImageGalleryDialog);
