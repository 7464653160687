import { staticStyles } from './maps';

const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY;

export const getLocationImage = (width, height, lat, lng, zoom = 15) => {
  const center = `center=${encodeURIComponent(`${lat},${lng}`)}`;
  const markers = `markers=${encodeURIComponent(`color:red|${lat},${lng}`)}`;
  const size = `size=${encodeURIComponent(`${width}x${height}`)}`;
  const key = `key=${GOOGLE_MAP_API_KEY}`;

  return `https://maps.googleapis.com/maps/api/staticmap?${[staticStyles, center, markers, size, zoom, key].join('&')}`;
};

export const openMail = (link) => `mailto:?&subject=&body=${link}`;

export const openInGoogleMaps = (country, city, address_name) => {
  const q = encodeURIComponent(`${country}, ${city}, ${address_name}`);
  return `https://maps.google.com/?q=${q}`;
};
