import { GetServerSidePropsContext, NextPageContext } from 'next';

const withGetInitialPropsRouteValidation =
  (querySchema: any, getInitialProps?: any) => async (ctx: NextPageContext | GetServerSidePropsContext) => {
    const { query } = ctx;

    try {
      querySchema.validateSync({
        ...query,
      });
      if (getInitialProps) {
        return getInitialProps(ctx);
      }
      return {};
    } catch (e) {
      return { notFound: true };
    }
  };

export default withGetInitialPropsRouteValidation;
