import React from 'react';
import { NextPage } from 'next';
import NotFoundPage from 'pages/404';

const withRouteValidation = <P extends {}, IP = P>(Page: NextPage<P, IP>) => {
  const Wrapper: NextPage<P, any> = (props: any) => {
    const { notFound } = props;

    return notFound ? <NotFoundPage /> : <Page {...props} />;
  };

  Wrapper.getInitialProps = async (ctx) => {
    let pageProps = {};
    if (Page.getInitialProps) {
      pageProps = await Page.getInitialProps(ctx);
    }

    return {
      ...pageProps,
    };
  };

  return Wrapper;
};

export default withRouteValidation;
