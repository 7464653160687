import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  className?: string;
}

const useStyles = makeStyles()(() => ({
  overlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: 'rgba(0 , 0, 0, .6)',
  },
  icon: {
    color: 'white',
  },
}));

const VideoPreviewOverlay: React.FC<React.PropsWithChildren<IProps>> = ({ className }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.overlay, className)}>
      <PlayArrowIcon className={classes.icon} fontSize="large" />
    </div>
  );
};

export default VideoPreviewOverlay;
