import React, { memo } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useVideoThumb } from 'hooks';
import VirtualIcon from 'components/svg/virtual.svg';
import VideoPreviewOverlay from 'components/common/VideoPreviewOverlay';

interface IProps {
  component?: React.ElementType;
  file: {
    link: string;
  };
}

type TypeMap<D extends React.ElementType = 'img'> = {
  props: IProps;
  defaultComponent: D;
  classKey: string;
};

const VideoImage: React.FC<React.PropsWithChildren<IProps>> = ({ file, component: Component = 'img', ...props }) => {
  const videoImg = useVideoThumb(file.link);

  return (
    <div {...props}>
      <VideoPreviewOverlay />
      <Component src={videoImg} alt={videoImg} placeholder={<VirtualIcon />} />
    </div>
  );
};

export default memo(VideoImage) as OverridableComponent<TypeMap>;
