import React from 'react';
import { Grid, Typography } from '@mui/material';
import Image from 'next/legacy/image';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';

const useStyles = makeStyles()((theme) => ({
  imageContainer: {
    position: 'relative',
  },
  text: {
    position: 'absolute',
    top: '63%',
    left: '25%',
    maxWidth: 320,
    [theme.breakpoints.down('md')]: {
      position: 'inherit',
      top: 0,
      left: 0,
      maxWidth: 'unset',
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
}));

const NotFoundContent = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('public');
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item className={classes.imageContainer}>
        <Image src="/static/svg/404.svg" alt="404" layout="intrinsic" width={1023} height={416} />
        <Typography variant="h6" className={classes.text}>
          {t<string>('screen.notFoundPage.description')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFoundContent;
