import React from 'react';
import { NextPage } from 'next';
import { Box, Container } from '@mui/material';

import { TabTitle } from 'components/common/TabTitle';
import NotFoundContent from 'components/pageNotFound/NotFoundContent';
import { UserHeader } from 'components/common/Header';

const NotFoundPage: NextPage = () => (
  <>
    <TabTitle ns="public" i18nKey="tab.pageNotFound" signLabel="general" />
    <UserHeader />
    <Container maxWidth="xl">
      <Box mt={10}>
        <NotFoundContent />
      </Box>
    </Container>
  </>
);

export default NotFoundPage;
